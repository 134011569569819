import React, { Component } from 'react'
import { Link, navigate, StaticQuery, graphql } from 'gatsby'

import { Twitter, Instagram, Facebook, Youtube, Email } from './icons'

import logo from '../assets/images/small_logo.svg'

class Footer extends Component {

  state = {
    input: '',
  }

  _keyPress = (event) => {
    if (event.keyCode !== 13) {
      return
    }

    this.setState({ input: '' })
    navigate(`/search?query=${event.currentTarget.value}`)
  }

  _renderTag(el, i) {
    return (
      <li key={i}>
        <Link to={`/search?query=${el.node.name}`}>{ el.node.name }, </Link>
      </li>
    )
  }

  render() {

    let { tags, data } = this.props

    let inputProps = {
      ref: 'search',
      type: 'text',
      placeholder: 'Search The Father Hood',
      value: this.state.input,
      onKeyDown: this._keyPress,
      onChange: (event) => this.setState({ input: event.currentTarget.value })
    }

    return (
      <footer className='footer'>
        <div className='footer__inner'>

          <div className='footer__side'>
            <input {...inputProps} />
            <p className='footer__copyright'>&copy; The Father Hood 2018, All rights reserved.</p>
          </div>

          <div className='footer__center'>
            <img src={logo} alt='the father hood logo' />
            <h3>The Father Hood</h3>
            <p>{ `Australia's #1 place for dads` }</p>

            <ul className='footer__links'>
              <li><Link to='/workplace-support'>Workplace Support</Link></li>
              <li><Link to='/terms-conditions'>Terms & Conditions</Link></li>
              <li><Link to='/contact'>Contact</Link></li>
            </ul>

            <div className='footer__tags'>
              <h4>Topics</h4>
              <ul>
                { tags.map(this._renderTag) }
              </ul>
            </div>
          </div>

          <div className='footer__side'>
            <div className='footer__social'>
              <a href={data.options.options.twitter} target='_blank' rel='nofollow noopener noreferrer'><Twitter /></a>
              <a href={data.options.options.instagram} target='_blank' rel='nofollow noopener noreferrer'><Instagram /></a>
              <a href={data.options.options.facebook} target='_blank' rel='nofollow noopener noreferrer'><Facebook /></a>
              <a href={data.options.options.youtube} target='_blank' rel='nofollow noopener noreferrer'><Youtube /></a>
              <a href='mailto:luke@the-father-hood.com' target='_blank' rel='nofollow noopener noreferrer'><Email /></a>
            </div>
            <div>
              <p className='footer__attr'>Design <a href="https://atollon.com.au/" target="_blank" rel="noopener noreferrer">Atollon</a></p>
            </div>
          </div>

        </div>
      </footer>
    )
  }
}


export default props => (
  <StaticQuery
    query={graphql`
      query {
        options: wordpressAcfOptions(wordpress_id: {eq: "acf"}) {
          options {
            twitter
            instagram
            facebook
            youtube
          }
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
